.testimonials {
  display: flex;
  gap: 20px;
  margin-top: 2rem;
}

.testLeft {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
}

.header {
  font-size: 3rem;
  font-weight: bold;
  color: white;
}

.headerGreen {
  font-size: 3rem;
  font-weight: bold;
  color: #42b72a;
}
.review {
  color: white;
}

.reviewer {
  display: flex;
  gap: 5px;
}
.name {
  color: white;
  font-weight: bold;
}

.prof {
  color: #42b72a;
  font-weight: bold;
}

.testRight {
  flex: 1;
  display: flex;
  gap: 2rem;
  align-items: center;
}

.ready {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.line {
  border-top: 3px solid #42b72a;
  width: 50%;
}

.headerSecond {
  font-size: 3rem;
  font-weight: bold;
  color: white;
}

.headerSecondgreen {
  font-size: 3rem;
  font-weight: bold;
  color: #42b72a;
}

.arrow {
  display: flex;
  gap: 10px;
}

.arrowLogo {
  font-size: 3rem;
  color: white;
  cursor: pointer;
}
.animation {
  position: relative;
}
.div1 {
  position: absolute;
  border: 2px solid #42b72a;
  height: 17rem;
  width: 14rem;
  z-index: 1;
  top: -9rem;
}

.div2 {
  position: absolute;
  height: 17rem;
  width: 14rem;
  background-color: #42b72a;
  z-index: 2;
  top: -7rem;
  left: 2rem;
}

.imgDiv {
  position: absolute;
  z-index: 3;
  height: 17rem;
  width: 14rem;
  left: 1rem;
  top: -8rem;
}

.imgDiv > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .testimonials {
    display: none;
  }
}
